body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #454F63 !important;
  background: #f0f3f5 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #454F63 !important;
}
a {
  color: #628CC3 !important;
}
.icon-push {
  -webkit-animation: icon-push 0.5s linear;
  animation: icon-push 0.5s linear;
}
@-webkit-keyframes icon-push {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes icon-push {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.icon-pulse {
  -webkit-animation: icon-pulse 2s infinite linear;
  animation: icon-pulse 2s infinite linear;
}
@-webkit-keyframes icon-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes icon-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-wrapper-body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}
.clickable-icon {
  cursor: pointer !important;
}
.search-focused {
  border-radius: 0px !important;
}
.search-focused input {
  border-radius: 0px !important;
}
.search-list-container {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 51px);
  background: white;
  z-index: 9999;
  overflow: auto;
}
.menu-drawer .ant-drawer-body {
  padding: 0 !important;
}
.menu-drawer .ant-drawer-body .ant-list-item {
  padding-left: 15px;
}
.menu-drawer .ant-drawer-body .ant-list-item:last-child {
  border-bottom: 1px solid #cccccc;
}
.ant-drawer {
  z-index: 9 !important;
}
.logout-menu-item {
  position: fixed !important;
  bottom: 0;
}
.ant-btn:not(.btn-brand):not(.btn-cancel):not(.btn-neutral) {
  border-radius: 10px !important;
}
.ant-btn:not(.btn-brand):not(.btn-cancel):not(.btn-neutral):hover {
  color: #0A1B3F !important;
  border-color: #0A1B3F !important;
}
.ant-btn:not(.btn-brand):not(.btn-cancel):not(.btn-neutral):active {
  color: #0A1B3F !important;
  border-color: #0A1B3F !important;
}
.ant-btn:not(.btn-brand):not(.btn-cancel):not(.btn-neutral):focus {
  color: #0A1B3F !important;
  border-color: #0A1B3F !important;
}
.header {
  background: #0A1B3F;
  color: white;
  text-align: center;
  position: relative;
  padding: 30px 30px 0px 30px;
}
.workflow-feedback {
  text-align: center !important;
  width: 200px !important;
}
.workflow-feedback .ant-modal-body {
  font-size: 20px !important;
}
.client-not-found {
  color: #454F63 !important;
}
.footer {
  display: flex;
  place-content: center space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top: 1px solid #cccccc;
}
.footerText {
  display: flex;
  place-content: center space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  border-top: 1px solid #cccccc;
}
#curve {
  position: absolute !important;
  bottom: 0px !important;
  left: 0px;
}
.ant-page-header-content-view {
  padding-top: 0 !important;
  background: white !important;
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-switch-checked {
  background-color: #0A1B3F !important;
}
.wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  padding-right: 20px;
  padding-left: 20px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.logout-container {
  position: absolute;
  right: 0;
  top: 0;
  padding: 17px;
}
.btn-brand {
  background: #0A1B3F !important;
  border-color: #0A1B3F !important;
  color: white !important;
  font-weight: 700 !important;
}
.btn-brand:disabled {
  background: rgba(0, 0, 0, 0.25) !important;
  border: rgba(0, 0, 0, 0.25) !important;
  color: #454F63 !important;
}
.btn-cancel {
  background: #e74c3c !important;
  border-color: #e74c3c !important;
  color: white !important;
  font-weight: 700 !important;
}
.btn-neutral {
  background: white !important;
  border-color: #454F63 !important;
  color: #454F63 !important;
  font-weight: 700 !important;
}
.btn-soft {
  background: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16) !important;
  border: none !important;
  color: #454F63 !important;
  font-weight: 700 !important;
}
.ant-modal-content {
  border-radius: 10px !important;
}
.ant-modal-header {
  border-radius: 10px 10px 0px 0px !important;
}
.d-none {
  display: none;
}
.basePanel {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
  color: #454F63;
  border: none;
}
.baseCard {
  background: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16) !important;
  color: #454F63 !important;
  border: none !important;
}
.baseCard .ant-card-actions {
  border-radius: 0px 0px 10px 10px;
}
.ant-menu-vertical {
  border: none !important;
}
.ant-card {
  margin-bottom: 30px !important;
}
.listCard .ant-card-body {
  padding: 0px;
}
.listCard .ant-card-body .ant-list-item {
  padding: 24px;
}
.listCard .ant-card-body .statistic-row,
.listCard .ant-card-body .ant-card-loading-content {
  padding: 25px;
}
.ant-statistic-content {
  font-size: 18px !important;
}
.ant-statistic-content-suffix {
  font-size: 14px !important;
}
.baseInput {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
  color: #454F63;
  border: none;
  font-weight: 700 !important;
  height: 50px !important;
  z-index: 99;
  border: none !important;
  font-weight: 700;
  border-radius: 10px !important;
}
.baseInput::-webkit-input-placeholder {
  color: #cccccc !important;
}
.baseInput::-moz-placeholder {
  color: #cccccc !important;
}
.baseInput::-ms-input-placeholder {
  color: #cccccc !important;
}
.baseInput::placeholder {
  color: #cccccc !important;
}
.baseInput::-webkit-inner-spin-button {
  display: none;
}
.baseInput::-webkit-calendar-picker-indicator {
  display: none;
}
.baseInput::-webkit-clear-button {
  display: none;
}
.baseInput input {
  padding-left: 50px !important;
  border: none !important;
  font-weight: 700;
  border-radius: 10px !important;
}
.baseInput input:hover {
  border: none !important;
  box-shadow: none !important;
}
.footerInput {
  border: none !important;
  box-shadow: none !important;
}
.footerInput:hover {
  border: none !important;
  box-shadow: none !important;
}
input[type="date"]:before,
input[type="time"]:before {
  margin-right: 20px;
  color: lightgray;
  content: attr(placeholder);
}
input[type="date"],
input[type="time"] {
  display: flex;
}
.baseInputData {
  width: 100% !important;
}
.baseInputData input {
  background: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16) !important;
  color: #454F63 !important;
  border: none !important;
  font-weight: 700 !important;
  height: 50px !important;
  padding-left: 20px !important;
}
.baseInputData input::-webkit-input-placeholder {
  color: #cccccc !important;
}
.baseInputData input::-moz-placeholder {
  color: #cccccc !important;
}
.baseInputData input::-ms-input-placeholder {
  color: #cccccc !important;
}
.baseInputData input::placeholder {
  color: #cccccc !important;
}
.ant-input-disabled {
  background: #dddddd !important;
  cursor: not-allowed;
}
.ant-input-disabled::-webkit-input-placeholder {
  color: #bebebe !important;
}
.ant-input-disabled::-moz-placeholder {
  color: #bebebe !important;
}
.ant-input-disabled::-ms-input-placeholder {
  color: #bebebe !important;
}
.ant-input-disabled::placeholder {
  color: #bebebe !important;
}
.wbgi {
  border: 1px solid #e4e4e4 !important;
}
.customRadioRadio {
  width: 100% !important;
}
.customRadio {
  text-align: center;
  height: 115px !important;
  padding: 25px !important;
  width: 50% !important;
  box-shadow: none !important;
}
.happyFlow {
  border-color: #0A1B3F !important;
  color: #0A1B3F !important;
}
.happyFlow.ant-radio-button-wrapper-checked {
  background: #0A1B3F !important;
  color: white !important;
}
.unhappyFlow {
  border-color: #e74c3c !important;
  color: #e74c3c !important;
}
.unhappyFlow.ant-radio-button-wrapper-checked {
  background: #e74c3c !important;
  color: white !important;
}
.has-error input[type="date"]:before,
.has-error input[type="time"]:before {
  color: #edaca5 !important;
}
.has-error .baseInput {
  border: 2px solid #e74c3c !important;
}
.has-error .baseInput::-webkit-input-placeholder {
  color: #edaca5 !important;
}
.has-error .baseInput::-moz-placeholder {
  color: #edaca5 !important;
}
.has-error .baseInput::-ms-input-placeholder {
  color: #edaca5 !important;
}
.has-error .baseInput::placeholder {
  color: #edaca5 !important;
}
.has-error .baseInputData input {
  border: 2px solid #e74c3c !important;
}
.has-error .baseInputData input::-webkit-input-placeholder {
  color: #edaca5 !important;
}
.has-error .baseInputData input::-moz-placeholder {
  color: #edaca5 !important;
}
.has-error .baseInputData input::-ms-input-placeholder {
  color: #edaca5 !important;
}
.has-error .baseInputData input::placeholder {
  color: #edaca5 !important;
}
.has-error .baseSelect .ant-select-selection {
  border: 2px solid #e74c3c !important;
}
.has-error .baseSelect .ant-select-selection .ant-select-selection__placeholder {
  color: #edaca5 !important;
}
.baseRangePicker {
  width: 100% !important;
  height: 50px !important;
}
.baseRangePicker .ant-calendar-picker-input {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
  color: #454F63;
  border: none;
  height: 100% !important;
}
.baseRangePicker .ant-calendar-picker-input .ant-calendar-range-picker-separator {
  vertical-align: inherit !important;
}
.baseRangePicker .ant-calendar-picker-input .ant-calendar-range-picker-input {
  font-size: 16px;
  font-weight: 700;
}
.loading-container-drawer {
  font-size: 50px;
  display: flex;
  justify-content: center;
  height: calc(100vh - 55px - 48px);
  align-items: center;
}
.s-alert-box {
  z-index: 999999 !important;
}
.card-body-p0 .ant-card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.card-body-p0 .ant-card-body .ant-collapse-item:first-child {
  border-top: 1px solid #d9d8d8 !important;
}
.baseSelect {
  z-index: 99;
  width: 100%;
  background: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16) !important;
  color: #454F63 !important;
  border: none !important;
  height: 50px !important;
}
.baseSelect .ant-select-selection__rendered {
  line-height: 50px;
  font-weight: 700 !important;
  font-size: 16px;
}
.baseSelect .ant-select-selection {
  height: 50px;
  border: none;
  border-radius: 10px;
  padding-left: 10px !important;
}
.baseSelect .ant-select-selection__placeholder {
  color: #cccccc !important;
}
.mobileSelect {
  width: 100% !important;
  padding-left: 20px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.ant-form-explain {
  margin-top: 5px !important;
}
.new-btn {
  background: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16) !important;
  color: #454F63 !important;
  border: none !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  text-transform: uppercase;
  height: 100% !important;
  padding: 10px !important;
}
.ant-btn-group .ant-btn {
  border-radius: 15px !important;
}
.chooseOne {
  cursor: pointer;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.16);
  color: #454F63;
  border: none;
  margin-top: 30px;
  padding: 20px;
}
.chooseOne img {
  width: 50px;
}
.currency-drawer .ant-drawer-body {
  padding: 0 !important;
}
.pre-loader {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #0A1B3F;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: white;
  flex-direction: column;
}
.pre-loader p {
  font-size: 14px !important;
}
.map-header-container {
  position: fixed;
  top: 0;
  z-index: 99999;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.map-footer-container {
  position: fixed;
  bottom: 16px;
  z-index: 999;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.moreDetailsDrawer .ant-drawer-content {
  overflow: auto;
}
.moreDetailsDrawer .ant-drawer-header {
  background: white !important;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.moreDetailsDrawer .ant-drawer-body {
  position: relative;
  top: 60px;
}
.leaflet-popup-close-button {
  display: none;
}
.leaflet-routing-container {
  display: none !important;
}
.ant-menu-item-selected {
  background-color: #f1f1f1 !important;
  color: #0A1B3F !important;
}
.ant-menu-item-active {
  background-color: #f1f1f1 !important;
  color: #0A1B3F !important;
}
.ant-layout-sider {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02), 0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035), 0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05), 0 100px 80px rgba(0, 0, 0, 0.07);
  z-index: 9999;
}
.display-data-table tr td {
  padding: 10px 0px;
}
.pois-controller-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.map-loader-backdrop {
  height: 100%;
  position: absolute;
  z-index: 9;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
}
.marker-pulse-box {
  float: left;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.marker-pulse-css {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #628CC3;
  position: relative;
}
.marker-pulse-css:before,
.marker-pulse-css:after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #628CC3;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
          animation: pulse-me 3s linear infinite;
}
.ant-form-item-control {
  line-height: inherit !important;
}
.marker-pulse-css:after {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.ant-radio-button-wrapper:hover {
  color: #0A1B3F !important;
}
.ant-radio-button-wrapper-checked {
  border-color: #0A1B3F !important;
  color: #0A1B3F !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 #0A1B3F !important;
}
.custom-checkbox {
  border: 1px solid #cccccc;
  font-weight: 700;
  border-radius: 4px;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  height: 50px;
  width: 100%;
}
.custom-checkbox .ant-checkbox {
  display: none !important;
}
.custom-checkbox .ant-checkbox + span {
  padding: 0 !important;
}
.custom-checkbox.ant-checkbox-wrapper-checked {
  border: 2px solid #0A1B3F;
  color: #0A1B3F !important;
}
@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(5);
            transform: scale(5);
    opacity: 0;
  }
}
@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(5);
            transform: scale(5);
    opacity: 0;
  }
}
.m-0 {
  margin: 0px;
}
.m-3 {
  margin: 3px;
}
.m-5 {
  margin: 5px;
}
.m-10 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-20 {
  margin: 20px;
}
.m-25 {
  margin: 25px;
}
.m-30 {
  margin: 30px;
}
.m-35 {
  margin: 35px;
}
.m-40 {
  margin: 40px;
}
.m-45 {
  margin: 45px;
}
.m-50 {
  margin: 50px;
}
.mt-0 {
  margin-top: 0px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mr-0 {
  margin-right: 0px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-50 {
  margin-right: 50px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-3 {
  margin-bottom: 3px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.ml-0 {
  margin-left: 0px;
}
.ml-3 {
  margin-left: 3px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-50 {
  margin-left: 50px;
}
.p-0 {
  padding: 0px;
}
.p-3 {
  padding: 3px;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-35 {
  padding: 35px;
}
.p-40 {
  padding: 40px;
}
.p-45 {
  padding: 45px;
}
.p-50 {
  padding: 50px;
}
.pt-0 {
  padding-top: 0px;
}
.pt-3 {
  padding-top: 3px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pr-0 {
  padding-right: 0px;
}
.pr-3 {
  padding-right: 3px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-50 {
  padding-right: 50px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-3 {
  padding-bottom: 3px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pl-0 {
  padding-left: 0px;
}
.pl-3 {
  padding-left: 3px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-50 {
  padding-left: 50px;
}
.final-footer-spacing {
  margin-bottom: 90px !important;
}

.login-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url(/static/media/bg.208a0b0a.jpg);
}
.login-panel {
  width: 500px;
  text-align: left;
  min-height: 100%;
  padding: 70px 50px;
  background-color: #ffffff;
}
.ant-form-explain {
  margin-bottom: 20px;
}

.login-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url(/static/media/bg.208a0b0a.jpg);
}
.login-panel {
  width: 500px;
  text-align: left;
  min-height: 100%;
  padding: 70px 50px;
  background-color: #ffffff;
}
.ant-form-explain {
  margin-bottom: 20px;
}

.login-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url(/static/media/bg.208a0b0a.jpg);
}
.login-panel {
  width: 500px;
  text-align: left;
  min-height: 100%;
  padding: 70px 50px;
  background-color: #ffffff;
}
.ant-form-explain {
  margin-bottom: 20px;
}

.login-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-image: url(/static/media/bg.208a0b0a.jpg);
}
.login-panel {
  width: 500px;
  text-align: left;
  min-height: 100%;
  padding: 70px 50px;
  background-color: #ffffff;
}
.ant-form-explain {
  margin-bottom: 20px;
}

